import React, { useState, useEffect } from 'react';
import './App.css';
import About from './components/About';
import Education from './components/Education';
import Skills from './components/Skills';
import Experience from './components/Experience';

function App() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1000);
    };

    // Initial check and add event listener
    handleResize();
    window.addEventListener('resize', handleResize);

    // Cleanup on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="App">
      <div className="columns-wrapper">
        {isSmallScreen ? (
          <div className="small-screen">
            <About />
            <Skills />
            <Education />
            <Experience />
          </div>
        ) : (
          <>
            <div className="left-column">
              <Skills />
              <Education />
            </div>
            <div className="right-column">
            <About />
              <Experience />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default App;
