import React, { useState } from 'react';
import styles from '../../styles/Company.module.css'; // Replace with the correct module file

const JuniperEducation = () => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const skills = [
    'C#',
    '.NET Framework',
    '.NET Standard',
    '.NET Core 3.0',
    'React',
    'Typescript',
    'MSSQL',
    'Git',
    'Azure',
  ];

  return (
    <div className={styles.ProfessionalExperience}>
      <div className={styles.CompanyInfo} onClick={toggleCollapse}>
        <div className={styles.CompanyName}>Juniper Education</div>
        <div className={styles.JobTitle}>Software Developer</div>
        <div className={styles.DateContainer}>March 2020 - April 2022</div>
      </div>
      {!collapsed && (
        <div>
          <div className={styles.JobResponsibilities}>
            <ul>
<li>Developed a web-based forum from scratch using .NET and JavaScript, including data migration.</li>
<li>Maintained and enhanced web applications using C#, .NET Framework, JavaScript, TypeScript, and SQL Server</li>
<li>Implemented Unit Tests (NUnit for .Net and Jest for Typescript)</li>
<li>Advocated for the adoption of Git and JetBrains Rider</li>
<li>Collaborated in pair programming and individually tabled features and bug fixes.</li>
<li>Mentored Junior Developers</li>
<li>Utilized Razor to facilitate backend-frontend interaction.</li>
<li>Created .Net Standard Libraries</li>
<li>Actively participated in sprint reviews, gathering valuable feedback from stakeholders</li>
            </ul>
          </div>
          <div className={styles.Tech}>
            <p className={styles.TechTitle}>Tech:</p>
            <ul className={styles.TechList}>
              {skills.map((skill, index) => (
                <li key={index} className={styles.TechItem}>
                  {skill}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default JuniperEducation;