import React, { useState } from 'react';
import styles from '../../styles/Company.module.css'; // Replace with the correct module file

const LMS = () => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const skills = [
    'C#',
    '.NET Framework',
    'MySQL',
    'Git',
    'Azure',
    'CI/CD',
    'Microservices'

  ];

  return (
    <div className={styles.ProfessionalExperience}>
      <div className={styles.CompanyInfo} onClick={toggleCollapse}>
        <div className={styles.CompanyName}>LMS</div>
        <div className={styles.JobTitle}>Software Developer</div>
        <div className={styles.DateContainer}>April 2022 - July 2022</div>
      </div>
      {!collapsed && (
        <div>
          <div className={styles.JobResponsibilities}>
            <ul>
              <li>Automated portions of the client onboarding process to improve efficiency.</li>
              <li>Maintained and enhanced microservices within the legacy codebase, including manual deployment of services without Azure Pipelines.</li>
              <li>Managed Azure Pipelines for projects that had pipeline integration.</li>
              <li>Developed and maintained unit tests to ensure code quality and stability.</li>
              <li>Implemented manual deployment procedures for legacy code, involving zipping files, comparing DLL changes, and manual file transfers for service deployment.</li>
              <li>Safely handled sensitive data with strict adherence to security protocols.</li>
            </ul>
          </div>
          <div className={styles.Tech}>
            <p className={styles.TechTitle}>Tech:</p>
            <ul className={styles.TechList}>
              {skills.map((skill, index) => (
                <li key={index} className={styles.TechItem}>
                  {skill}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default LMS;


