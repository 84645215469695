import React from 'react';
import styles from '../styles/Experience.module.css'; // Replace with the correct module file
import BJSS from './ExperienceComponents/BJSS';
import LMS from './ExperienceComponents/LMS';
import JuniperEducation from './ExperienceComponents/JuniperEducation';
import DoorFunds from './ExperienceComponents/DoorFunds';


const Experience = () => {
  return (
    <div className={styles.experienceSection}>
      <h2>Professional Experience</h2>
      <BJSS />
      <LMS />
      <JuniperEducation />
      <DoorFunds />
    </div>
  );
};

export default Experience;