import React, { useState } from 'react';
import styles from '../../styles/Company.module.css'; // Replace with the correct module file

const BJSS = () => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={styles.ProfessionalExperience}>
      <div className={styles.CompanyInfo} onClick={toggleCollapse}>
        <div className={styles.CompanyName}>BJSS</div>
        <div className={styles.JobTitle}>Software Consultant</div>
        <div className={styles.DateContainer}>July 2023 - Ongoing</div>
      </div>
      {!collapsed && (
        <div>
          <div className={styles.JobResponsibilities}>
            <ul>
<li>Played an active role in improving and maintaining a major healthcare project</li>
<li>Contributed as a core member of a de-risking team, identifying and mitigating any future threats that would affect the service</li>
<li>Developed efficient solutions using Python and TypeScript.</li>
<li>Utilized Lambda, AWS, Docker, and DynamoDB to create scalable cloud-based features.</li>
<li>Embraced Agile methodologies through project management tools and the adoption of Scrum, Agile, and Kanban approaches.</li>
<li>Implemented CI/CD pipelines, streamlining software delivery and optimizing development processes.</li>
<li>Successfully executed complex data migration projects, minimizing disruptions and safeguarding data integrity.</li>
<li>Modernized a legacy platform using best practices to enhance overall functionalit</li>
            </ul>
            </div>
          <div className={styles.Tech}>
            <p className={styles.TechTitle}>Tech:</p>
            <ul className={styles.TechList}>
              <li className={styles.TechItem}>Python</li>
              <li className={styles.TechItem}>TypeScript</li>
              <li className={styles.TechItem}>AWS</li>
              <li className={styles.TechItem}>DynamoDB</li>
              <li className={styles.TechItem}>Docker</li>
              <li className={styles.TechItem}>CI/CD</li>
              <li className={styles.TechItem}>Pipelines</li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};


export default BJSS;
