import React, { useState } from 'react';
import styles from '../../styles/Company.module.css'; // Replace with the correct module file

const DoorFunds = () => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const skills = [
'Java 8',
'Spring',
'Javascript',
'React',
'Redux',
'Python',
'Ruby',
'Gherkin and Cucumber',
'MySQL',
'AWS'
  ];

  return (
    <div className={styles.ProfessionalExperience}>
      <div className={styles.CompanyInfo} onClick={toggleCollapse}>
        <div className={styles.CompanyName}>Door Funds</div>
        <div className={styles.JobTitle}>Junior Software Developer</div>
        <div className={styles.DateContainer}>June 2018 - April 2020</div>
      </div>
      {!collapsed && (
        <div>
          <div className={styles.JobResponsibilities}>
            <ul>
<li>Performed live database operations, executing queries to extract, modify, and anonymize client data.</li>
<li>Created an open-source Python project to develop a data anonymization tool.</li>
<li>Troubleshot and provided short-term bug fixes while developing permanent solutions throughout the development cycle.</li>
<li>Practiced agile development methodologies, participating in weekly sprints and daily stand-ups.</li>
<li>Utilized Git for version control and collaborated with the team on task and feature implementation.</li>
<li>Developed a support system using Trello, employing React, Redux, and Java technologies.</li>

            </ul>
          </div>
          <div className={styles.Tech}>
            <p className={styles.TechTitle}>Tech:</p>
            <ul className={styles.TechList}>
              {skills.map((skill, index) => (
                <li key={index} className={styles.TechItem}>
                  {skill}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default DoorFunds;