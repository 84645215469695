import React from 'react';
import styles from '../styles/Education.module.css';

const Education = () => {
  return (
    <div className={styles.educationSection}>
      <h2>Education</h2>
      <div className={styles.educationInfo}>
        <div className={styles.degree}>BSc (Hons) Soaftware Engineering (Result: 1st)</div>
        <div className={styles.university}>Liverpool John Moore’s University</div>
        <div className={styles.date}>2014 - 2018</div>
      </div>
    </div>
  );
};

export default Education;
