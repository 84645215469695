import React from 'react';
import styles from '../styles/About.module.css';

const About = () => {
  return (
    <div className={styles.aboutSection}>
      <div className={styles.name}>
        <span className={styles.firstname}>Jon</span>
        <span className={styles.surname}>Russell</span>
      </div>
      <div className={styles.jobTitle}>Software Engineer</div>
      <p className={styles.description}>
      Hello there! I'm a dedicated Software Developer with extensive expertise and proficiency in C# and the Microsoft technology stack.
      Guided by SOLID principles and a comprehensive understanding of design patterns, I regard programming languages as my instruments of creation. 
      Adapting to the distinct demands of each project, I am adept at choosing the most fitting language to achieve optimal outcomes.
      </p>
    </div>
  );
};

export default About;
