import React from 'react';
import styles from '../styles/Skills.module.css';

const Skills = () => {
  const skillCategories = [
    {
      category: 'Languages',
      items: ['C#', 'Python', 'Java', 'Javascript','Typescript', 'Ruby'],
    },
    {
      category: 'Frameworks',
      items: ['.NET Framework','React', '.NET Core', 'Spring Boot', 'Ruby on Rails'],
    },
    {
      category: 'Tools',
      items: ['AWS', 'Azure', 'CI/CD','Docker','DynamoDB','Git', 'GitHub', 'GitLab', 'MSSQL','MySQL','Pipelines'],
    }
  ];

  return (
    <div className={styles.skillsSection}>
      <h2>Skills</h2>
      <div className={styles.categoriesContainer}>
        {skillCategories.map((category, index) => (
          <div key={index} className={styles.skillCategory}>
            <h3 className={styles.categoryTitle}>{category.category}</h3>
            <div className={styles.skillItems}>
              {category.items.map((item, itemIndex) => (
                <div key={itemIndex} className={styles.skillItem}>
                  {item}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Skills;
